<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">E-Research Articles</p>
      </div>
      <div class="column is-narrow">
        <b-button
          v-if="false"
          icon-left="plus"
          class="is-primary"
          @click="addItem"
          >Add E-Research Articles</b-button
        >
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <div class="columns is-vcentered">
            <div class="column is-narrow bold">Search by:</div>
            <div class="column">
              <b-radio
                class="mt-1 pl-2"
                v-for="(columnData, index) in tableProps.columnData"
                :key="index"
                v-model="searchType"
                name="searchType"
                :native-value="columnData"
                @input="filterItems"
              >
                {{ tableProps.columnTitle[index] }}
              </b-radio>
            </div>
          </div>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Go to page:">
          <b-input v-model="tableProps.page" type="number" min="1"></b-input>
        </b-field>
      </div>
    </div>
    <hr />
    <b-table
      :data="filteredItems"
      :loading="tableProps.isLoading"
      :striped="tableProps.isStriped"
      :total="tableProps.total"
      :per-page="tableProps.perPage"
      :current-page="parseInt(tableProps.page)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
    >
      <b-table-column
        v-for="(columnData, index) in tableProps.columnData"
        :key="index"
        :field="columnData"
        :label="tableProps.columnTitle[index]"
        sortable
        v-slot="props"
      >
        {{
          Array.isArray(props.row[columnData])
            ? props.row[columnData].join(", ")
            : props.row[columnData] | filter
        }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" sortable v-slot="props">
        <span
          class="has-text-success pointer-active"
          @click="openViewDetailModal(props.row)"
        >
          <i class="mdi mdi-eye pr-1"></i> View
        </span>
        <span
          class="has-text-info pointer-active ml-4"
          @click="editItem(props.row)"
        >
          <i class="mdi mdi-pencil pr-1"></i> Edit
        </span>
        <span
          class="has-text-danger pointer-active ml-4"
          @click="openDeleteItemModal(props.row)"
        >
          <i class="mdi mdi-delete pr-1"></i> Delete
        </span>
      </b-table-column>
    </b-table>
    <!-- Modals Start -->
    <b-modal v-model="modals.showViewDetailModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">E-Research Article Details</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitlesData"
                :key="index"
                :label="modals.viewModalTitles[index]"
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
            <div class="column is-6 has-text-centered mr-4">
              <div class="mt-2">
                <a v-if="resourceURL" :href="resourceURL">
                  <b-button type="is-info" icon-left="download">
                    Download E-Journal
                  </b-button>
                </a>
                <a v-else-if="resourceURL === null">
                  <b-button type="is-info" icon-left="download" disabled>
                    No file uploaded
                  </b-button>
                </a>
                <a v-else>
                  <b-button type="is-info" icon-left="download" disabled>
                    Loading...
                  </b-button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeViewDetailModal" />
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="modals.showDeleteItemModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete E-Research Article</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <b-message type="is-danger">
            <div class="columns is-vcentered">
              <div class="column">
                Are you sure you want to delete this E-Research Article?
              </div>
              <div class="column is-narrow">
                <b-button label="Cancel" @click="closeDeleteItemModal" />
                <b-button
                  label="Confirm"
                  @click="deleteItem(itemDetail._id)"
                  type="is-danger"
                  class="ml-2"
                />
              </div>
            </div>
          </b-message>
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitlesData"
                :key="index"
                :label="modals.viewModalTitles[index]"
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
import { orderBy, filter } from "lodash";

export default {
  name: "Viewer",
  data() {
    return {
      filteredItems: undefined,
      searchQuery: undefined,
      searchType: "title",
      tableProps: {
        columnTitle: ["Title", "Author(s)"],
        columnData: ["title", "authors"],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Title",
          "Subject",
          "Description",
          "Keywords",
          "Authors",
          "Publication Year",
          "Publisher",
          "Place of Publication",
          "URL Link",
          "Premium Content",
          "Kids Content",
          "DateCreated"
        ],
        viewModalTitlesData: [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "publisher",
          "placeOfPublication",
          "weblink",
          "premiumContent",
          "kidsContent",
          "dateCreated"
        ]
      },
      itemDetail: undefined
    };
  },
  async created() {
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getEResearch");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
  },
  computed: {
    items() {
      return orderBy(this.$store.state.eResearch, ["dateCreated"], ["desc"]);
    },
    resourceURL() {
      return this.$store.state.resourceUrl;
    }
  },
  watch: {
    items: function (val) {
      this.filteredItems = val;
    }
  },
  methods: {
    openViewDetailModal(selectedItem) {
      this.itemDetail = selectedItem;
      const request = {
        resourceId: selectedItem._id,
        resourceType: "eResearch"
      };
      this.$store.dispatch("setResourceUrl", undefined);
      this.$store.dispatch("getResourceUrl", request);
      this.modals.showViewDetailModal = true;
    },
    closeViewDetailModal() {
      this.modals.showViewDetailModal = false;
    },
    openDeleteItemModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showDeleteItemModal = true;
    },
    closeDeleteItemModal() {
      this.modals.showDeleteItemModal = false;
    },
    async deleteItem(itemId) {
      const deleteResourceRequest = {
        resourceId: itemId,
        resourceType: "eResearch",
        type: "both"
      };
      await this.$store.dispatch("resourceDelete", deleteResourceRequest);
      await this.$store.dispatch("deleteEResearch", itemId);
      this.closeDeleteItemModal();
    },
    addItem() {
      this.$router.push({ name: "EResearchEditor" });
    },
    editItem(item) {
      this.$router.push({
        name: "EResearchEditor",
        params: { itemDetail: item }
      });
    },
    filterItems() {
      if (this.searchQuery === "") {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = filter(this.items, (item) => {
          return item[this.searchType]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
